<template lang="pug">
  BInputGroup.app-search
    template(v-slot:prepend)
      BInputGroupText.search-icon
        FaIcon(icon="search")
    BFormInput.search-input(
      v-on="$listeners"
      type="search"
      :placeholder="inputPlaceholder"
      v-bind="$props"
    )
</template>

<script>
  export default {
    props: {
      name: {
        title: String,
        default: "search"
      },
      debounce: {
        type: Number,
        default: 500
      },
      disabled: {
        type: Boolean,
        default: false
      },
      placeholder: String
    },

    computed: {
      inputPlaceholder() {
        return this.placeholder || this.$t("components.app_search.free_word_search")
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .app-search
    width: 400px

    .search-icon
      background-color: $default-gray-light
      border: 0
      height: 34px
      padding: 6px 1px 6px 12px

      svg
        color: $default-purple

    .search-input
      background-color: $default-gray-light
      border: 0
      height: 34px

      &:focus
        outline: none
        box-shadow: none

      &::placeholder
        color: #adb4be
        font-size: 0.8rem
</style>
